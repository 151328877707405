/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Journeys is my venture into creating a website tracking application, a project driven by my long-standing ambition. This application is designed to transcend traditional analytics, focusing on the user's journey rather than just metrics. My goal is to make decisions based on solid data, providing insights into how users interact with websites, what captures their interest, and what doesn't."), "\n", React.createElement(_components.p, null, "For the backend, I've chosen to experiment with Supabase, particularly intrigued by its PostgreSQL functions. Supabase has impressed me with its comprehensive API capabilities, offering a robust and efficient way to manage data. However, I've encountered some limitations with its bucket features, which was somewhat disappointing. On the frontend, I'm using React paired with Tailwind CSS."), "\n", React.createElement(_components.p, null, "I'm still testing things out so its not ready for public use yet, but I'm excited to see where this project goes."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
